<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="Registration Form Generator"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div class="bg-primary-white mt-5 p-5">
      <Sections />
    </div>
    <FormTypeModal :model="toggleFormStepModal" />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import FormTypeModal from '@/src/router/views/registration-process/form-generator/add-new-tab/AddRegistrationStepModal.vue'
import { mapActions, mapState } from 'vuex'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import Sections from '@views/registration-process/form-generator/tab-sections/TabSection.vue'
export default {
  components: {
    TitleWrapper,
    FormTypeModal,
    Sections,
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.layout.isLoading,
      toggleFormStepModal: (state) => state.student.toggleFormStepModal,
    }),
  },
  created() {
    this.setRightbarData()
  },
  methods: {
    setRightbarData(count = 0) {
      const rightBarContent = {
        header: {
          title: 'Registration Form Generator',
          buttons: [
            {
              title: 'Add Step',
              classes: ['primary-button-right'],
              isDropdown: true,
              options: [
                {
                  title: 'Form',
                  action: { name: 'student/addStepForForm', payload: { type: 'form' } },
                },
                {
                  title: 'Attachments',
                  action: {
                    name: 'student/addStepForForm',
                    payload: { type: 'attachments' },
                  },
                },
                {
                  title: 'Fee Submission',
                  action: {
                    name: 'student/addStepForForm',
                    payload: { type: 'fee-submission' },
                  },
                },
              ],
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [{ text: 'Total Pending Registrations', value: count }],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },

    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
