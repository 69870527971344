<template>
  <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
  <UiModalContainer
    footer-classes="flex justify-end gap-3"
    :modal-show="model"
    :modal-width="50"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span>Add Form Step</span>
    </template>
    <template v-slot>
      <div class="py-6 px-6">
        <div class="flex flex-col">
          <div class="">
            <InputBox
              v-model="title"
              type="text"
              name="Title"
              title="Title"
              label="Title"
              placeholder="Enter Title"
              class="flex-1 w-full"
              rules="required"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
        @click="handleClick('close')"
      >
        Cancel
      </button>
      <button
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
        @click.prevent="handleSubmit(addFormType(invalid))"
      >
        <span>Add</span>
      </button>
    </template>
  </UiModalContainer>
  <!-- </ValidationObserver> -->
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { mapState } from 'vuex'
export default {
  components: {
    UiModalContainer,
    InputBox,
    // ValidationObserver,
  },
  props: {
    model: { type: Boolean, default: false },
  },
  data() {
    return {
      title: '',
    }
  },
  computed: {
    ...mapState({
      currentStepForRegistration: (state) => state.student.currentStepForRegistration,
    }),
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$store.commit('student/SET_FORM_TYPE_MODAL', false)
          this.title = ''
          break
      }
    },
    addFormType(invalid) {
      if (invalid) return
      this.$store.commit('student/SET_FORM_TYPE_MODAL', false)

      if (this.currentStepForRegistration === 'form')
        this.$router?.push({ name: 'form', params: { title: this.title } })
      else return
      this.title = ''
    },
  },
}
</script>
